<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.procedimento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.procedimento.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Procedimento', 'Inserir')"
      sem-filtro
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    />
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Procedimento', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :mostrar-seletor="buscarPermissao('Procedimento', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="_ => listarRegistros()"
      @filtroGlobal="v => filtroAplicado.filter = v"
      @nova-ordenacao="ordenacaoColuna"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('Procedimento', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"

              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ProcedimentoService from '@common/services/cadastros/ProcedimentoService';
import helpers from '@common/utils/helpers';

export default {
  components: {
    CabecalhoPagina,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.procedimento.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'titulo',
            text: this.$t('modulos.procedimento.tabela.titulo'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created(){
    helpers.redirecionarSemPermissao(this, 'Procedimento', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };

    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.procedimento.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function () {
      delete this.filtroAplicado.buscar;
      this.listarRegistros();
    },
    listarRegistros(paginaAtual = this.tabela.paginaAtual, porPagina = this.tabela.porPagina) {
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, { filtroAplicado: this.filtroAplicado, paginaAtual, porPagina});
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ProcedimentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        ProcedimentoService.excluir(this.tabela.selecionados[0].id)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(this.$t(`modulos.procedimento.exclusao_sucesso`));
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'procedimento-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'procedimento-editar',
        params: { id: item.id },
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
